import {
  faSackDollar,
  faLightbulbDollar,
  faBuildings,
  faUserGroup,
  faUserHeadset,
  faUsersGear,
  faXmark,
  faCity
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"
import { Outlet, useLocation } from "@tanstack/react-location"
import { Fragment, Suspense, lazy, useEffect, useMemo, useState } from "react"
import Loader from "src/components/base/misc/Loader"
import { truthy } from "src/helpers/arrays"
import { getEnv } from "src/helpers/env"
import { useHasPermission } from "src/hooks/auth"
import quortexLogo from "src/images/quortex-logo.svg"
import DesktopNavItem from "./layout/DesktopNavItem"
import MobileNavItem from "./layout/MobileNavItem"
import TopNavBar from "./layout/TopNavBar"
import TopSearch from "./layout/TopSearch"
import { TNavItem } from "./layout/types"

const Welcome = lazy(() => import("src/components/landings/Welcome"))

const Main = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const { pathname } = location.current
  const hasAccess = useHasPermission("backoffice:access", { orgUuid: undefined })
  const canSeeCogs = useHasPermission("billing:read_cogs", { orgUuid: undefined })

  const mainNavItems: TNavItem[] = useMemo(
    () =>
      [
        { icon: faBuildings, link: { to: "/organizations" }, name: "Organizations" },
        { icon: faUserGroup, link: { to: "/users" }, name: "Users" },
        getEnv() !== "tech" && {
          icon: faSackDollar,
          link: { to: "/billing" },
          name: "Billing accounts"
        },
        canSeeCogs && {
          basePath: "/cogs",
          icon: faLightbulbDollar,
          link: { to: "/cogs/cost" },
          name: "Cogs"
        }
      ].filter(truthy),
    [canSeeCogs]
  )

  const adminNavItems: TNavItem[] = useMemo(
    () => [
      { icon: faUserHeadset, link: { to: "/operators" }, name: "Operators" },
      { icon: faUsersGear, link: { to: "/teams" }, name: "Teams" },
      { icon: faCity, link: { to: "/organization-groups" }, name: "Organization groups" }
    ],
    []
  )

  useEffect(() => {
    setSidebarOpen(wasOpen => (wasOpen ? false : wasOpen))
  }, [pathname])

  if (!hasAccess) return <Welcome />

  return (
    <>
      <Transition as={Fragment} show={sidebarOpen}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 pb-4 pt-5">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <FontAwesomeIcon aria-hidden className="h-6 w-6 text-white" icon={faXmark} />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex flex-shrink-0 items-center whitespace-nowrap px-4 text-xl font-bold tracking-wide text-white">
                <img alt="" className="mr-3 h-8 w-auto" src={quortexLogo} />
                Back-office
              </div>
              <div className="mt-4 h-0 flex-1 overflow-y-auto pt-1">
                <TopSearch className="dark mb-3 ml-5 mr-4" dropdownClassName="w-[17.5rem]" />
                <nav className="space-y-1 px-2 py-4">
                  {mainNavItems.map(item => (
                    <MobileNavItem key={item.name} item={item} />
                  ))}
                </nav>
                <nav className="space-y-1 px-2 py-4">
                  <div className="px-2 text-xs font-bold leading-6 text-gray-400">
                    Administration
                  </div>
                  {adminNavItems.map(item => (
                    <MobileNavItem key={item.name} item={item} />
                  ))}
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div aria-hidden className="w-14 flex-shrink-0">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-56 md:flex-col lg:w-64">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-900">
          <div className="flex h-16 flex-shrink-0 items-center whitespace-nowrap bg-gray-900 px-4 text-xl font-bold tracking-wide text-white">
            <img alt="" className="mr-3 h-8 w-auto" src={quortexLogo} />
            Back-office
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav className="space-y-1 px-2 py-4">
              {mainNavItems.map(item => (
                <DesktopNavItem key={item.name} item={item} />
              ))}
            </nav>
            <nav className="space-y-1 px-2 py-4">
              <div className="px-2 text-xs font-bold leading-6 text-gray-400">Administration</div>
              {adminNavItems.map(item => (
                <DesktopNavItem key={item.name} item={item} />
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-auto flex-col items-center md:pl-56 lg:pl-64">
        <div className="fixed left-0 right-0 z-10 md:ml-56 lg:ml-64">
          <TopNavBar onOpenSidebar={() => setSidebarOpen(true)} />
        </div>

        <main className="mb-32 flex w-full max-w-7xl flex-auto flex-col pt-16">
          <Suspense fallback={<Loader isFullPage />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  )
}

export default Main
